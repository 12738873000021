import React from "react";
import "../../styling/templates.css";

const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    textAlign: "left",
  },
  td: {
    textAlign: "left",
  },
};

function App() {
  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");

    const formData = new FormData(event.target);

    // Set the email subject
    formData.append("subject", "BPPL Careers Center Form Test");

    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
      setResult(res.message);
    } else {
      console.log("Error", res);
      setResult(res.message);
    }
  };

  return (
    <div className="about-us-form">
      <h2 className="about-us-form-topic">Careers Centre</h2>
      <br />
      <h2 className="about-us-form-topic-2">
        <div style={{ marginTop: "-10px" }}>Be a part of BPPL group</div>
      </h2>
      <br />
      <p className="about-us-form-para">
        At our companies, more than 900 people are employed. As demand for our
        products and services increases, so does our need for talented
        individuals to join us.
      </p>
      <br />
      <form onSubmit={onSubmit}>
        <label className="about-us-form-label">Available Position:</label>
        <br />
        <select name="position" required className="about-us-form-select">
          <option value="GM">GM</option>
          <option value="VP">VP</option>
          <option value="AGM">AGM</option>
        </select>
        <br />
        <br />
        <label className="about-us-form-label">Upload CV:</label>
        <br />
        <div className="about-us-form-cv">
          <input type="file" required name="attach" />
        </div>
        <br />
        <br />
        <input
          type="hidden"
          name="ccemail"
          value="info@bpplholdings.com; admin@3cs.lk"
        />

        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>
                <input className="submitButton" type="submit" />
              </th>
              <th style={styles.th}>
                <div className="success-message" style={{ marginLeft: "20px" }}>
                  <span>{result}</span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <br />
      </form>
    </div>
  );
}

export default App;
