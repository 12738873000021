import React, { useEffect, useState } from "react";
import "../../styling/templates.css";
import BPPLhistory from "../../images/BPPL_History.jpg";
import Heder from "../../components/navigation-header";
import Footer from "../../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import From from "./form";
import MainBanner from "../../images/main_banner.jpg";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //   para 1
  const [readMore1, setReadMore1] = useState(false);
  const extraContent1 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Business Experience Mr. Sarath Dayantha Amarasinghe is a Chartered
        Engineer by profession. A Member of the Institute of Mechanical
        Engineers, UK – M I Mech E, a Member of the Institute of Marine
        Engineers, UK – M I Mar E and a Member of the Institute of Engineers,
        Sri Lanka – MIE (S.L). He is also a Member of the Institute of Chartered
        Engineers, UK. He counts over 35 years of service at Colombo Commercial
        Company (Engineers) Limited., of which he served as its General Manager/
        Managing Director for a period of 10 years. He also served as Chairman/
        Managing Director at Alumex Group of Companies for a period of Seven
        years.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Beira Brush (Pvt) Ltd</li>
        <li>Eco Spindles (Pvt) Ltd</li>
        <li>BPPL Enterprises (Pvt) Ltd</li>
        <li>Infinity Capital (Pvt) Ltd</li>
      </ul>
    </div>
  );
  const para1 = readMore1 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 2
  const [readMore2, setReadMore2] = useState(false);
  const extraContent2 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Dr. Amarasinghe holds a Bachelor of Science and a Ph.D. degree in
        Electronics Engineering from the Loughborough University of Technology,
        UK, and is a highly experienced investor with many years of hands-on
        business management experience. Dr. Amarasinghe began his career as a
        Research Engineer at Thorn EMI Central Research Laboratories, UK where
        he patented two inventions on low cost, low energy consuming electronic
        ballasts for lighting equipment. Between 1993 and 1998, Dr. Amarasinghe
        worked at SG Securities as an investment research analyst and
        subsequently as an investment banker. He was an early investor in
        Millennium Information Technologies (MillenniumIT) and in 1999 joined
        MillenniumIT as its Chief Financial Officer. He was elected to the Board
        in 2001 and was appointed as its Chief Operating Officer in 2004. In
        2009, MillenniumIT was sold to the London Stock Exchange Group, UK, and
        Dr. Amarasinghe left the company in 2012, after serving a mandatory
        three-year post-sale agreement. Whilst at MillenniumIT, Dr. Amarasinghe
        was also a founding partner and investor in E-Channelling. Dr.
        Amarasinghe was also a director and an early investor in Alumex
        Anodising and Machine Tools (Pvt) Limited. BPPL Holdings PLC (BPPL) is
        Dr. Amarasinghe’s most recent investment. He acquired the company in
        2012.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Beira Brush (Pvt) Ltd</li>
        <li>Eco Spindles (Pvt) Ltd</li>
        <li>BPPL Enterprises (Pvt) Ltd</li>
        <li>Infinity Capital (Pvt) Ltd</li>
      </ul>
    </div>
  );
  const para2 = readMore2 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 3
  const [readMore3, setReadMore3] = useState(false);
  const extraContent3 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Mr. Selvaraj holds an MBA from the Australian Institute of Business, is
        an Associate Member of the Chartered Institute of Management
        Accountants, UK (ACMA) and a CGMA and Associate Member of the Institute
        of Data Processing Management (AIDPM). He is also a Graduate Member of
        the Sri Lanka Institute of Directors. In terms of business management
        experience, Mr. Selvaraj counts over 37 years in the manufacturing
        sector, out of which 35 years were in senior managerial positions in the
        areas of finance, supply chain management, export sales, IT and general
        management. He served as the Chief Accountant of the Phoenix Group of
        Companies for nine years, and has been a Director of Moosajees (Pvt)
        Limited for 27 years. Mr. Selvaraj is also on the boards of Beira Brush
        (Pvt) Limited, Eco Spindles (Pvt) Limited, BPPL Enterprises (Pvt)
        Limited and Moosajees (Pvt) Limited. He is also a Non- Executive
        Director of Moot Investments (Pvt) Limited.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Beira Brush (Pvt) Ltd</li>
        <li>Eco Spindles (Pvt) Ltd</li>
        <li>BPPL Enterprises (Pvt) Ltd</li>
        <li>Moosajees (Pvt) Ltd</li>
        <li>Moot Investments (Pvt) Ltd</li>
      </ul>
    </div>
  );
  const para3 = readMore3 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 4
  const [readMore4, setReadMore4] = useState(false);
  const extraContent4 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Mr. Perera, who is a science graduate with a second class from the
        University of Colombo and a certified Director of the Sri Lanka
        Institute of Directors, is the chief operating officer at Beira Brush
        (Pvt) Limited. Starting his career at BPPL as a Management Trainee in
        1991, Mr. Perera has served the Company for 31 years in various
        capacities. His previous employment was at Brandix Lanka Limited, as a
        sectional head. Mr. Perera also holds Director positions at Beira Brush
        (Pvt) Limited, Eco Spindles (Pvt) Limited and BPPL Enterprises (Pvt)
        Limited.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Beira Brush (Pvt) Ltd</li>
        <li>Eco Spindles (Pvt) Ltd</li>
        <li>BPPL Enterprises (Pvt) Ltd</li>
      </ul>
    </div>
  );
  const para4 = readMore4 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 5
  const [readMore5, setReadMore5] = useState(false);
  const extraContent5 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Mr. Ranil Pathirana has extensive experience in finance and management
        in financial, apparel and energy sectors and presently serves as a
        Director of Hirdaramani Apparel Holdings (Private) Limited, Hirdaramani
        Leisure Holdings (Private) Limited and Hirdaramani Investment Holdings
        (Private) Limited which are the holding companies of the Hirdaramani
        Group. He is also the Managing Director for Hirdaramani International
        Exports (Pvt) Limited. Mr. Pathirana is the Chairman of Windforce PLC
        and a Non-Executive Director of Ambeon Capital PLC, Ceylon Hotels
        Corporation PLC, ODEL PLC & Alumex PLC. He is a Fellow Member of the
        Chartered Institute of Management Accountants, UK and holds a Bachelor
        of Commerce Degree from the University of Sri Jayewardenepura.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Beira Brush (Pvt) Ltd</li>
        <li>Eco Spindles (Pvt) Ltd</li>
        <li>BPPL Enterprises (Pvt) Ltd</li>
        <li>Windforce PLC</li>
        <li>Alumex PLC</li>
        <li>Ceylon Hotels Corporation PLC </li>
        <li>Ambeon Capital PLC</li>
        <li>Odel PLC</li>
      </ul>
    </div>
  );
  const para5 = readMore5 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 6
  const [readMore6, setReadMore6] = useState(false);
  const extraContent6 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Savantha De Saram, is the Senior Partner of M/s D. L. & F. De Saram,
        Attorneys-at- Law. He holds a LLB (Hons) from Holborn Law College
        London, and is a Barrister-of- Law (of Lincoln’s Inn) and an
        Attorney-at- Law. He has been in practice for over 22 years. He
        currently serves as a Non- Executive director of Hunters & Co. PLC and
        Windforce PLC.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Hunters and Co. PLC</li>
        <li>Windforce PLC</li>
      </ul>
    </div>
  );
  const para6 = readMore6 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 7
  const [readMore7, setReadMore7] = useState(false);
  const extraContent7 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Mr. Manjula De Silva holds a BA Hons (1st Class) degree in Economics
        from the University of Colombo and a MBA from London Business School,
        UK. He is also a FCMA (UK) and a CGMA. Mr. De Silva held the positions
        of CEO and Managing Director at HNB Assurance PLC and Chairman at the
        National Insurance Trust Fund (NITF). He is currently the Secretary
        General and CEO at the Ceylon Chamber of Commerce. He has formerly held
        positions at AIA Insurance, NDB Wealth Management and the Public
        Enterprises Reform Commission (PERC). He has also served as the Chairman
        of CIMA (Chartered Institute of Management Accountants) Sri Lanka Board.
        He was also a commission member of the Securities and Exchange
        Commission of Sri Lanka (SEC).
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Central Finance Company PLC</li>
        <li>
          Tertiary and Vocational Education Commission of Sri Lanka (TVEC)
        </li>
      </ul>
    </div>
  );
  const para7 = readMore7 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  //   para 8
  const [readMore8, setReadMore8] = useState(false);
  const extraContent8 = (
    <div className="directors-read-more">
      <p className="directors-read-more-topic">Business Experience</p>
      <p>
        Ms. Sharmini Ratwatte is a Fellow member of the Chartered Institute of
        Management Accountants, UK and holds a Master of Business Administration
        from the University of Colombo. Sharmini is a Non-Executive Director at
        Ceylon Cold Stores PLC. She is a Trustee of the Sunera Foundation which
        works in the field of arts for the differently abled children. She is
        also a Founder Trustee of the Federation of Environmental Organisations.
        Ms. Sharmini Ratwatte was recognised as the Zonta “Woman of Achievement
        – Management” in 2004.
      </p>
      <p className="directors-read-more-topic">Other Directorships</p>
      <ul>
        <li>Ceylon Cold Stores</li>
        <li>Trustee at Sunera Foundation</li>
      </ul>
    </div>
  );
  const para8 = readMore8 ? (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;Less</span>
      <br />
      <div
        style={{ marginRight: "13px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faCaretUp} />
      </div>
    </span>
  ) : (
    <span>
      <span style={{ marginLeft: "0px" }}>Read&nbsp;More</span>
      <br />
      <div
        style={{ marginRight: "10px", textAlign: "right", marginTop: "-15px" }}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </span>
  );

  return (
    <div>
      <Heder />

      <center>
        <div className="section-background">
          <h2 className="about-us-topic">
            Driving ambition, <br />
            building a sustainable future
          </h2>
        </div>
      </center>

      <div className="pc">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100vh",
            marginBottom: "100px",
          }}
        >
          <div className="overlayStyle6"></div>
        </div>
      </div>
      <div className="mobile">
        <div
          style={{
            backgroundImage: `url(${MainBanner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "700px",
            marginBottom: "60px",
          }}
        ></div>
      </div>

      <center>
        <div className="section-background">
          <h2 className="about-us-topic">About BPPL Holdings PLC</h2>

          <div className="two-column-paragraph-container">
            <div className="two-column-text">
              {/* Left column content */}
              <p>
                We are one of Asia's largest brush manufacturers, a large
                plastics upcycler, and the only polyester yarn producer in Sri
                Lanka.
                <br />
                <br />
                Our goal is to create a world where nothing goes to waste -
                starting with our own operations. To date, our upcycling efforts
                have prevented over 571 million plastic bottles from ending up
                in landfills. And, as the demand for sustainable products
                continues to grow, so does our commitment to being the leading
                producer of eco-friendly cleaning tools and filaments in the
                region.
              </p>
            </div>

            <div className="two-column-text">
              {/* Right column content */}
              <div className="mobile" style={{ marginTop: "-75px" }}></div>
              <div className="br-tab">
                <p>
                  At our company, sustainability is key. Our innovative and
                  responsible practices have made us one of the leading names in
                  environmental, social, and governance (ESG) impact in Asia. As
                  proud champions of sustainability, we focus on creating
                  environmentally-friendly cleaning tools, monofilaments, and
                  polyester yarns for both professional and household markets
                  worldwide. We offer a variety of production lines that allow
                  us to create privately labelled cleaning tools, synthetic
                  monofilaments for brush producers, and recycled polyester
                  yarns for fabric mills.
                  <br />
                  <br />
                  <img
                    className="image-c"
                    src="/images/ABOUT_US/bppl-about-us-logos.jpg"
                    alt="about-us-logo"
                    loading="lazy"
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="horizontal-line"></div>
        </div>
      </center>

      <center>
        <div className="section-background">
          <h2 className="about-us-topic">Our history & milestones</h2>

          {/* /images/ABOUT_US/BPPL_History1.jpg */}
          <img
            src={BPPLhistory}
            style={{ width: "100%", marginBottom: "60px" }}
            alt="bppl-history"
            loading="lazy"
          />
        </div>
      </center>

      <div>
        <div className="grid-container-about">
          <div
            className="grid-item-about"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("/images/ABOUT_US/BPPL_PORPOSE.jpg")`,
              maxWidth: "100%",
            }}
          >
            <div className="card-content-about">
              <h2 className="about-card-topic">Our purpose</h2>
              <div className="horizontal-line-card-about" />
              <p className="about-card-para">
                Create a world without waste. Preserve our planet for the
                longest period.
              </p>
            </div>
          </div>
          <div
            className="grid-item-about"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("/images/ABOUT_US/BPPL_People.jpg")`,
              maxWidth: "100%",
            }}
          >
            <div className="card-content-about">
              <h2 className="about-card-topic">Our people</h2>
              <div className="horizontal-line-card-about" />
              <p className="about-card-para">
                Diversity and inclusion are key components of our workplace
                environment.
              </p>
            </div>
          </div>
          <div
            className="grid-item-about"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("/images/ABOUT_US/BPPL_goals.jpg")`,
              maxWidth: "100%",
            }}
          >
            <div className="card-content-about">
              <h2 className="about-card-topic">Our goals</h2>
              <div className="horizontal-line-card-about" />
              <p className="about-card-para">
                We want to grow profitably and sustainably, achieve
                environmental objectives and fulfil our purpose.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <center>
          <div className="section-background">
            <p className="about-para">
              Our subsidiaries Beira Brush (Private) Limited and Eco Spindles
              (Private) Limited have built a strong reputation among local and
              foreign customers for our ethical and value-driven business
              practices.
            </p>
          </div>
        </center>
      </div>

      {/* /////// */}

      <div className="pc">
        <center>
          <div className="section-background">
            <table style={{ width: "100%", textAlign: "start" }}>
              <tr>
                <td style={{ width: "50%", verticalAlign: "top" }}>
                  <div style={{ marginRight: "30px" }}>
                    {/* Left column content */}
                    <div className="two-column-text">
                      <img
                        src="/images/HOME/Beira-Brush-logo-Final.jpg"
                        alt="beira-brush-logo"
                        loading="lazy"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <p>
                          Beira Brush is one of South East Asia’s largest brush
                          manufacturers and is part of the largest plastic
                          recycling network in Sri Lanka. With over 35 years of
                          experience, each product is created with responsibly
                          sourced material and has found its place in the
                          household, commercial and professional sectors in
                          local and international markets.
                        </p>
                      </div>
                      <div style={{ marginTop: "50px" }}>
                        <a
                          href="https://www.beirabrush.com/"
                          target="_blank"
                          className="btn custom-button-about1 btn-sm"
                        >
                          VIEW WEBSITE
                        </a>
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  className="image-banner4"
                  style={{ width: "50%", verticalAlign: "top" }}
                ></td>
              </tr>
            </table>

            <div style={{ marginTop: "60px" }} />

            <table style={{ width: "100%", textAlign: "start" }}>
              <tr>
                <td
                  className="image-banner5"
                  style={{ width: "50%", verticalAlign: "top" }}
                ></td>

                <td style={{ width: "50%", verticalAlign: "top" }}>
                  <div style={{ marginLeft: "30px" }}>
                    {/* Right column content */}
                    <div className="two-column-text">
                      <img
                        src="/images/HOME/Es-logo-Final.jpg"
                        loading="lazy"
                        alt="es-logo"
                      />
                      <div style={{ marginTop: "20px" }}>
                        <p>
                          Eco Spindles is leading the way in upcycling with our
                          cutting-edge factories that can transform PET bottles
                          into high quality yarn and monofilaments. We are one
                          of only two plants in the world that have the
                          capability to create yarn directly from flakes. By
                          innovating the recycling process, we are making a
                          positive impact on the environment and creating
                          sustainable products that help make a difference.
                        </p>
                      </div>
                      <div style={{ marginTop: "50px" }}>
                        <a
                          href="https://www.ecospindles.com/"
                          target="_blank"
                          className="btn custom-button-about2 btn-sm"
                        >
                          VIEW WEBSITE
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </div>

      <div className="mobile">
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <center>
            <div
              style={{
                backgroundImage: `url("/images/ABOUT_US/beirabrush_products.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "250px",
              }}
            ></div>
            <br />
            <img
              src="/images/HOME/Beira-Brush-logo-Final.jpg"
              alt="beira-brush-logo"
              loading="lazy"
              style={{ Width: "100%", marginBottom: "10px" }}
            />
            <div className="two-column-text-mobile">
              <p>
                Beira Brush is one of South East Asia’s largest brush
                manufacturers and is part of the largest plastic recycling
                network in Sri Lanka. With over 35 years of experience, each
                product is created with responsibly sourced material and has
                found its place in the household, commercial and professional
                sectors in local and international markets.
              </p>
            </div>
            <div
              className="two-column-text-mobile"
              style={{ marginTop: "50px", marginBottom: "60px" }}
            >
              <a
                href="https://www.beirabrush.com/"
                target="_blank"
                className="btn custom-button-about1 btn-sm"
              >
                VIEW WEBSITE
              </a>
            </div>

            <div
              style={{
                backgroundImage: `url("/images/ABOUT_US/shutterstock_15912789431.jpg")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "250px",
              }}
            ></div>
            <img
              src="/images/HOME/Es-logo-Final.jpg"
              alt="es-logo"
              loading="lazy"
            />
            <div
              style={{ marginTop: "20px" }}
              className="two-column-text-mobile"
            >
              <p>
                Eco Spindles is leading the way in upcycling with our
                cutting-edge factories that can transform PET bottles into high
                quality yarn and monofilaments. We are one of only two plants in
                the world that have the capability to create yarn directly from
                flakes. By innovating the recycling process, we are making a
                positive impact on the environment and creating sustainable
                products that help make a difference.
              </p>
            </div>
            <div style={{ marginTop: "50px" }}>
              <a
                href="https://www.ecospindles.com/"
                target="_blank"
                className="btn custom-button-about2 btn-sm"
              >
                VIEW WEBSITE
              </a>
            </div>
          </center>
        </div>
      </div>

      {/* /////// */}

      <div>
        <center>
          <div className="section-background">
            <center>
              <h2 className="about-us-topic">Our global sales network</h2>
              <p className="about-list">
                Sri Lanka | Canada | USA | Mexico | Jamaica | Panama | Ireland |
                England | Netherlands | Denmark | France | Turkey | Dubai |
                India | Bangladesh | Indonesia | Australia | New Zealand
              </p>

              <img
                src="/images/ABOUT_US/map1.jpg"
                style={{ maxWidth: "100%", marginBottom: "40px" }}
                loading="lazy"
                alt="map"
              />
            </center>

            <div className="horizontal-line"></div>
          </div>
        </center>
      </div>

      <div>
        <center>
          <div className="section-background">
            <p className="about-para-2">
              We are proud to serve some of the world’s most popular brands,
              including Nike, Decathlon, Rubbermaid, Home Depot and Bunnings
              Warehouse.
            </p>
          </div>
        </center>
      </div>

      <div>
        <center>
          <div className="section-background">
            <table
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "60px",
                marginBottom: "30px",
              }}
            >
              <tr>
                <th style={{ width: "20%" }}>
                  <img
                    src="/images/ABOUT_US/rubber.jpg"
                    style={{ width: "100%" }}
                    alt="rubber"
                    loading="lazy"
                  />
                </th>
                <th style={{ width: "20%" }}>
                  <img
                    src="/images/ABOUT_US/oaets.jpg"
                    style={{ width: "100%" }}
                    alt="oaets"
                    loading="lazy"
                  />
                </th>
                <th style={{ width: "20%" }}>
                  <img
                    src="/images/ABOUT_US/deca.jpg"
                    style={{ width: "100%" }}
                    alt="deca"
                    loading="lazy"
                  />
                </th>
                <th style={{ width: "20%" }}>
                  <img
                    src="/images/ABOUT_US/home.jpg"
                    style={{ width: "100%" }}
                    alt="home"
                    loading="lazy"
                  />
                </th>
                <th style={{ width: "20%" }}>
                  <img
                    src="/images/ABOUT_US/nike1.jpg"
                    style={{ width: "100%" }}
                    alt="nike"
                    loading="lazy"
                  />
                </th>
              </tr>
            </table>
          </div>
        </center>
      </div>

      <div>
        <div
          style={{
            backgroundImage: `url("/images/ABOUT_US/brown-table-surrounded-with-green-chairs-lamps-room1.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "400px",
          }}
        >
          <div className="overlayStyle7"></div>
        </div>
      </div>

      <div>
        <div
          style={{
            marginTop: "-140px",
            marginLeft: "12%",
            marginRight: "12%",
          }}
        >
          <h2 className="directors-topic">Board of Directors</h2>
        </div>
      </div>

      <br />
      <div
        style={{
          marginLeft: "6%",
          marginRight: "6%",
          backgroundColor: "white",
          zIndex: "100",
          position: "relative",
          marginBottom: "60px",
        }}
      >
        <br />
        <center>
          <div style={{ marginLeft: "6%", marginRight: "6%" }}>
            {/* 1 */}
            <div class="responsive-table">
              <div class="table-row">
                <div class="table-cell">
                  {/* Left column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-Sarath-Dayantha-Amarasinghe.jpg"
                          style={{ width: "100%" }}
                          alt="Sarath-Dayantha-Amarasinghe"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. Sarath Dayantha Amarasinghe <br />
                            <div className="directors-position">Chairman</div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore1(!readMore1);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore1 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para1}
                              </p>
                            </a>
                            {readMore1 && extraContent1}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="table-cell">
                  {/* Right column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Anush-Amarasinghe.jpg"
                          style={{ width: "100%" }}
                          alt="Anush-Amarasinghe"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Dr. Anush Amarasinghe <br />
                            <div className="directors-position">
                              Managing Director/ Chief Executive Officer
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore2(!readMore2);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore2 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para2}
                              </p>
                            </a>
                            {readMore2 && extraContent2}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div class="responsive-table">
              <div class="table-row">
                <div class="table-cell">
                  {/* Left column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-Vaithilingam-Selvaraj.jpg"
                          style={{ width: "100%" }}
                          alt="Vaithilingam-Selvaraj"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. Vaithilingam Selvaraj <br />
                            <div className="directors-position">
                              Executive Director/Chief Financial Officer
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore3(!readMore3);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore3 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para3}
                              </p>
                            </a>
                            {readMore3 && extraContent3}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="table-cell">
                  {/* Right column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-B-D-Prasad-Devapriya-Perera.jpg"
                          style={{ width: "100%" }}
                          alt="B-D-Prasad-Devapriya-Perera"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. B D Prasad Devapriya Perera <br />
                            <div className="directors-position">
                              Executive Director
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore4(!readMore4);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore4 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para4}
                              </p>
                            </a>
                            {readMore4 && extraContent4}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* 3 */}
            <div class="responsive-table">
              <div class="table-row">
                <div class="table-cell">
                  {/* Left column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-Ranil-Prasad-Pathirana.jpg"
                          style={{ width: "100%" }}
                          alt="Ranil-Prasad-Pathirana"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. Ranil Prasad Pathirana <br />
                            <div className="directors-position">
                              Non-Executive Director
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore5(!readMore5);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore5 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para5}
                              </p>
                            </a>
                            {readMore5 && extraContent5}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="table-cell">
                  {/* Right column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-Savantha-De-Saram.jpg"
                          style={{ width: "100%" }}
                          alt="Savantha-De-Saram"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. Savantha De Saram <br />
                            <div className="directors-position">
                              Independent Non-Executive Director
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore6(!readMore6);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore6 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para6}
                              </p>
                            </a>
                            {readMore6 && extraContent6}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            {/* 4 */}
            <div class="responsive-table">
              <div class="table-row">
                <div class="table-cell">
                  {/* Left column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Mr.-Manjula-Hiranya-De-Silva.jpg"
                          style={{ width: "100%" }}
                          alt="Manjula-Hiranya-De-Silva"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Mr. Manjula Hiranya De Silva <br />
                            <div className="directors-position">
                              Independent Non-Executive Director
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore7(!readMore7);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore7 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para7}
                              </p>
                            </a>
                            {readMore7 && extraContent7}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="table-cell">
                  {/* Right column content */}
                  <table style={{ width: "100%", textAlign: "start" }}>
                    <tr>
                      <td className="table-2-left">
                        {/* image */}
                        <img
                          src="/images/ABOUT_US/Ms.-Sharmini-Ratwatte.jpg"
                          style={{ width: "100%" }}
                          alt="Sharmini-Ratwatte"
                          loading="lazy"
                        />
                      </td>

                      <td className="table-2-right">
                        {/* text */}
                        <div
                          style={{ paddingLeft: "12px", paddingRight: "10px" }}
                        >
                          <p className="directors-name">
                            Ms. Sharmini Ratwatte <br />
                            <div className="directors-position">
                              Independent Non-Executive Director
                            </div>
                          </p>
                          <div>
                            <a
                              className="directors-read"
                              style={{
                                display: "block",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setReadMore8(!readMore8);
                              }}
                            >
                              <p
                                style={{
                                  color: readMore8 ? "#6962b4" : "#1b1464",
                                }}
                              >
                                {para8}
                              </p>
                            </a>
                            {readMore8 && extraContent8}
                          </div>
                        </div>
                        <div className="horizontal-line" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </center>
      </div>

      <div
        style={{
          position: "relative",
          width: "100%",
          height: "auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/images/ABOUT_US/female-candidate-sitting-queue-receiving-cv-files-waiting-start-job-interview-about-career-opportunity-woman-getting-ready-attend-recruitment-meeting-with-hr-employee1.jpg")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <center>
          <div className="section-background-form">
            <br />
            <div style={{ marginTop: "60px", marginBottom: "60px" }}>
              <From />
            </div>
            <br />
          </div>
        </center>
      </div>

      <Footer />
    </div>
  );
}
